import React from "react";
import { Helmet } from "react-helmet";
import { useMedia } from "use-media";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "./hr-chatbot";
import { GoToPopup } from "../homepage";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";

const TopImage = require("../../assets/img/feature/logs/analytics_header.png");

const section1 = require("../../assets/img/feature/logs/analytics_sec_1.png");
const section2 = require("../../assets/img/feature/logs/analytics_sec_2.png");
const section3 = require("../../assets/img/feature/logs/analytics_sec_3.png");
const section4 = require("../../assets/img/feature/logs/analytics_sec_4.png");
const section5 = require("../../assets/img/feature/logs/analytics_sec_5.png");
const section6 = require("../../assets/img/feature/logs/analytics_sec_6.png");
const section7 = require("../../assets/img/feature/logs/analytics_sec_7.png");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const conversationAI = require("../../assets/img/feature/updated/c_ai.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const featureContent = [
  {
    header: "Bot Sessions",
    content:
      "Get a deeper understanding of chatbot usage by employees. Track how employees use chatbots, drill down on most helpful conversations, peak durations, average session duration, and much more.",
    image: section1,
    alt: "Bot Sessions",
    position: "right",
  },
  {
    header: "User Queries",
    content:
      "See how your user queries were handled by chatbot. Compare how query resolution, escalation, and or abandonment over a period of time.",
    image: section2,
    alt: "User Queries",
  },
  {
    header: "Agent Handover",
    content: "Get deeper insights into query escalation to live agents.",
    image: section3,
    position: "right",
    alt: "Agent Handover",
  },
  {
    header: "Live Bot Status",
    content:
      "View live usage of bot and user interaction from different chat channels.",
    image: section4,
    alt: "Live Bot Status",
  },
  {
    header: "User Metrics",
    content:
      "Deep dive into user metrics with bot usage over a period of time. Compare frequent with sporadic users and other key usage indicators.",
    image: section5,
    position: "right",
    alt: "User Metrics",
  },
  {
    header: "ROI",
    content:
      "Showing chatbot ROI to your manager or CFO? we go this covered for you with our ROI analytics. View costs savings, productivity improvements from bot and much more.",
    image: section6,
    alt: "ROI",
  },
  {
    header: "Unanswered Queries",
    content:
      "Review user queries that were unanswered and add dialogs to the bot for better topic coverage.",
    image: section7,
    position: "right",
    alt: "Unanswered Queries",
  },
];
export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: conversationAI,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approvals",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },

  {
    image: marketplace,
    alt: "Chatbot Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },

  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
];

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Chatbot Logs, Analytics and Trainings | Workativ Assistant Chatbot"
        description="Chatbot logs, analytics, and training provide the admin with data to help Improve chatbot performance, fine-tune chatbot dialogs and conversations, and explore ways to improve overall chatbot effectiveness."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Chatbot Logs, Analytics and Trainings | Workativ Assistant Chatbot"
        ogDescription="Chatbot logs, analytics, and training provide the admin with data to help Improve chatbot performance, fine-tune chatbot dialogs and conversations, and explore ways to improve overall chatbot effectiveness."
      />
      <Container additionalClass={"feature-display-none"}>
        <div className="logs_wrapper">
          <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
            <TopFormWithImage
              image={TopImage}
              altImage={"log, analytics & training - workativ assistant"}
              image_mob={TopImage}
              additionalClassSection={"features_indvidual_dec"}
              additionalClassImage={"width-100"}
            >
              <TopFormWithImage.Header>
                Chatbot Analytics & Performance
              </TopFormWithImage.Header>
              <TopFormWithImage.Content>
                Great dashboards are useless, said no one ever.<br></br> Monitor
                your chatbot performance easily from a single pane of glass.
                Deep dive into user queries,&nbsp;bot sessions, user metric, your ROI
                from bot, and much more.
              </TopFormWithImage.Content>
              <RequestForm isFooterForm={false} />
            </TopFormWithImage>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {featureContent.map((feature, index) => {
              const isFeature = index === 0;
              if (feature.position === "right") {
                return (
                  <RightImageWithContentFeature
                    isFeature={isFeature}
                    rightImage={feature.image}
                    image={feature.image}
                    altImage={feature.alt}
                    additionalClassImageWidth={
                      feature.additionalClassImageWidth
                    }
                    bgColor={index + 1 === featureContent.length ? "" : false}
                    additionalClass={
                      index + 1 === featureContent.length
                        ? "feature-padding-change"
                        : "feature-padding-change"
                    }
                  >
                    <RightImageWithContentFeature.Header>
                      <h3 className="font-section-header">
                        <span>{feature.header}</span>
                      </h3>
                    </RightImageWithContentFeature.Header>

                    <RightImageWithContentFeature.SubHeader>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    </RightImageWithContentFeature.SubHeader>
                    {feature.link && feature.url && (
                      <div className="card_link_landing">
                        <a
                          href={feature.url}
                          className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                        >
                          {feature.link}&nbsp; &#8594;
                        </a>
                      </div>
                    )}
                  </RightImageWithContentFeature>
                );
              }
              return (
                <div className="features_container_left">
                  <LeftImageWithContent
                    rightImage={feature.image}
                    image={feature.image}
                    altImage={feature.alt}
                  >
                    <LeftImageWithContent.HeaderIcon>
                      <h3 className="font-section-header">{feature.header}</h3>
                    </LeftImageWithContent.HeaderIcon>

                    <LeftImageWithContent.SubHeader>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    </LeftImageWithContent.SubHeader>
                    {feature.link && feature.url && (
                      <div className="card_link_landing">
                        <a
                          href={feature.url}
                          className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                        >
                          {feature.link}&nbsp; &#8594;
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent>
                </div>
              );
            })}

            <section className="cards_features">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                    <div className="build_needs">
                      <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                        <h2 className="font-section-header">
                          Explore More Features
                        </h2>
                        <p className="font-section-normal-text-testimonials line-height-18 container-width-padding">
                          Our 3-in-1 support automation platform combines the
                          power of Gen AI chatbot for automating chats, App
                          workflow automation for auto-resolution of issues, and
                          Shared Live Inbox for seamless live chat interactions.
                          And more…!
                        </p>
                      </div>
                    </div>
                    <section className="whitepaper_cards">
                      <div className="container-fluid p-0 c_a">
                        <div className="row">
                          <ul className="cards">
                            {otherFeatures.map((data) => (
                              <li className="cards__item">
                                <div className="card">
                                  <div className="card__image">
                                    <img
                                      loading="lazy"
                                      src={data.image}
                                      alt={data.alt}
                                    ></img>
                                  </div>
                                  <div className="card__content">
                                    <div className="card__title font-section-normal-text-medium">
                                      {data.header}
                                      {data.beta ? (
                                        <span className="all-features-new-launch font-section-small-signup-form">
                                          Beta
                                        </span>
                                      ) : null}
                                      {data.new ? (
                                        <span className="all-features-new-launch font-section-small-signup-form">
                                          New
                                        </span>
                                      ) : null}
                                    </div>
                                    <p className="card__text font-section-small-text pb-0">
                                      {data.content}
                                    </p>
                                    <div class="card_link_landing">
                                      <a
                                        class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                        href={data.link}
                                      >
                                        Know more&nbsp; &#8594;
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
            {/* <GoToPopup /> */}
            <div className="isfooter_form">
              <RequestForm isFooterForm={true} />
            </div>
          </Layout>
        </div>
      </Container>
    </>
  );
}
